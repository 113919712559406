<template>
    <div class="preferential flex fd-c ai-c">
        <img class="preferential-banner" :src="$route.query.picture" alt="" />
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="list"
        >
            <div class="flex fw-w">
                <div
                    class="preferential-item"
                    v-for="(item, index) in list"
                    :key="index"
                >
                    <v-loveGoods :goods="item" />
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
import loveGoodsList from "@/components/loveGoodsList";
import loveGoods from "@/components/loveGoods";
import { goodsTag } from "../../../api";
export default {
    name: "preferentialList",
    components: {
        "v-loveGoodsList": loveGoodsList,
        "v-loveGoods": loveGoods,
    },
    data() {
        return {
            current: 1,
            list: [],
            loading: false,
            finished: false,
        };
    },
    watch: {},
    computed: {},
    methods: {
        onLoad() {
            // 异步更新数据
            // setTimeout 仅做示例，真实场景中一般为 ajax 请求
            this.goodsTag();
        },
        goodsTag() {
            goodsTag({
                goodsTagId: 12,
                current: this.current,
                size: 20,
            }).then((res) => {
                var list = res.data.data.records;
                // 加载状态结束
                this.loading = false;
                this.current++;
                if (list.length == 0) {
                    this.finished = true;
                }
                for (var item of list) {
                    item.pic = item.pic ? item.pic.split(",")[0] : [];
                    this.list.push(item);
                }
            });
        },
    },
    created() {
    },
    mounted() {},
};
</script>
<style scoped>
.preferential {
    width: 100%;
}
.preferential-banner {
    width: 100%;
    height: 1.37rem;
    object-fit: cover;
    margin-top: .1rem;
}
.list {
    width: 100%;
    margin-top: 0.3rem;
}
.seeAll {
    width: 7.12rem;
    line-height: 0.85rem;
    text-align: center;
    background: #ffffff;
    border-radius: 0.04rem 0.04rem 0.14rem 0.14rem;
    font-size: 0.28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.preferential-item {
    margin-left: 0.233rem;
}
</style>